import React, { Component } from 'react';
import '../ProfileWidget/ProfileWidget.css';

class ProfileWidget extends Component {

    constructor(props){
        super(props);
        
        // initialize state
        this.state = {
            error: null,
            isLoaded: false,
            validationTitle: "There has been an error.",
            validationMessages: ["Please contact your system administrator for assistance."]
        }
    }

    componentDidMount(){
        
        // when it mounts, grab the data, or log error
        fetch('http://localhost:8888/json/profile_data.json')
            .then( (response) => {
              return response.json()})
                .then((myJson) => {

                    

                    this.setState((previousState) => {
                        return {
                        gid: myJson.Payload.Entity.GID,
                        id: myJson.Payload.Entity.ID,
                        status: myJson.Status === 'success' ? myJson.Status : 'error',
                        error: myJson.Status !== 'success' ? true : null,
                        studentId: myJson.Payload.StudentID,
                        surname: myJson.Payload.Entity.Surname,
                        givenName: myJson.Payload.Entity.GivenName,
                        primaryEmail: myJson.Payload.Entity.PrimaryEmail,
                        primaryAddressHtml: myJson.Payload.PrimaryAddress.HTMLValue,
                        primaryAddressText: myJson.Payload.PrimaryAddress.TextValue,
                        headshotLink: myJson.Payload.Entity.HeadshotLink,
                        isLoaded: true,
                        validtationTitle: myJson.Payload.ValidtationTitle !== null ? myJson.Payload.ValidationTitle : previousState.value,
                        validationMessages: myJson.Payload.ValidtationMessages ? myJson.Payload.ValidationMessages : previousState.value
                        }
                    })
                })
            
           
    }

    

    
    render(){

        // do rendering logic here
       

        // then return final state
        return(
            <div className="flexWidget smid lightBg" id="sm360Profile">
                <div id="profileSection">
                    <div className="widgetHeader blue" id="secProfileHeader">
                        <div className="smProfileImage">
                            <span>
                                <img alt={ this.state.givenName + ' ' + this.state.surname } src={this.state.headshotLink} />
                            </span>
                        </div>
                        <h2>{this.state.givenName + ' ' + this.state.surname}</h2>
                        <div className="smProfileLink">
                            <a href="?u4sm_page=my-profile">Edit My Profile</a>
                        </div>
                    </div>
                    <div className="widgetContent noMaxHeight" id="secProfileLoading" style={{display: 'none'}}>
                        <h3>Loading...</h3>
                    </div>
                    <div className="validationErrors" style={this.state.error === true ? { display: 'block' } : { display: 'none' } }>
                        <p data-bind="text: ErrorHeader"></p>
                        <ul data-bind="foreach: ErrorMessages"></ul>
                    </div>
                    <div className="widgetContent" id="secProfileContent">
                        <section className="fLeft">
                            <h3>ID: <span>{ this.state.studentId }</span></h3>
                            <address>
                                <div>{this.state.primaryAddressHtml}</div>
                            </address>
                        </section>
                    </div>
                </div>
            </div>
        )   
    }
}

export default ProfileWidget;