import React, { Component } from 'react';
import './App.css';
import ProfileWidget from './Components/ProfileWidget/ProfileWidget';
import NotificationWidget from './Components/NotificationWidget/NotificationWidget';
import FinancialAidWidget from './Components/FinancialAidWidget/FinancialAidWidget';
import BillingSummaryWidget from './Components/BillingSummaryWidget/BillingSummaryWidget';
import EnrollmentsWidget from './Components/EnrollmentsWidget/EnrollmentsWidget';
import CommunicationsWidget from './Components/CommunicationsWidget/CommunicationsWidget';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="smContentArea hideNav">
          <section id="smStudent360" data-bind="visible: IsAuthorized()">
            <ProfileWidget />
            <NotificationWidget />
            <FinancialAidWidget />
            <BillingSummaryWidget />
            <EnrollmentsWidget />
            <CommunicationsWidget />
          </section>
        </div>
      </div>
    );
  }
}

export default App;
