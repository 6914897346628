import React, { Component } from 'react';
import '../CommunicationsWidget/CommunicationsWidget.css';

class CommunicationsWidget extends Component {
    render(){
        return(
            <div className="flexWidget large lightBg noMinHeight" id="sm360Communications">
                <div data-bind="template: { name: TemplateName('StudentCommunicationsTemplate_{0}'), data: Communications }" id="communicationSection">
                    <div className="widgetHeader marine">
                        <span className="widgetIcon myCommunicationsIcon" ></span>
                        <h2>Communications</h2>
                    </div>
                    <div className="widgetContent">
                        <div style={{display: 'none'}}>Loading...</div>
                        <div className="validationErrors" id="CommunicationErrors" style={{display: 'none'}}>
                            <div className="fieldGroup">
                                <div className="field inline headerField">
                                    No Communications
                                </div>
                            </div>
                        </div>
                        <div data-bind="visible: Loaded() &amp;&amp; !IsError()">
                            <div className="tabSet" data-tabset-color="#ffffff" >
                                <ul role="tablist">
                                    <li data-activate-tab="unreadMessages" role="tab" className="activeTab" >
                                        Unread
                                        <span className="badge count" data-bind="text: UnreadCount">1</span>
                                    </li>
                                    <li data-activate-tab="archivedMessages" role="tab" className="" >
                                        Archived
                                        <span className="badge count" data-bind="text: ReadCount">0</span>
                                    </li>
                                    <li data-activate-tab="composeMessage" className="buttonTab fRight" data-bind="visible: NewMessageTabVisible()" style={{display: 'none'}} >New Message</li>
                                </ul>
                                <ul>
                                    <li data-tab-content="unreadMessages" data-default-tab="true" style={{display: 'list-item'}}>
                                        <div>
                                            <h2 style={{ color: '#333333' }}>Unread Messages</h2>
                                            <ul className="detailedList" id="unreadMessages">
                                                <li data-hasdetails="true" style={{cursor: 'pointer'}}>
                                                    <div className="listItem smMessages">
                                                        <span className="icon emailMessageIcon"></span>
                                                        <div className="itemSummary">
                                                            <span data-bind="text: DisplayFromAddress">scottm@threeriverssystems.com</span>
                                                            <span data-bind="text: Subject">CRM Ad-Hoc Communication</span>
                                                            <span data-bind="text: ReceivedDateJS"></span>
                                                        </div>
                                                        <span className="icon detailsIcon show"></span>
                                                        <div className="itemDetails">
                                                            <div className="buttonGroup">
                                                                <button data-reply-button="" data-bind="click: ReplyToMessage">Reply</button><button data-archive-button="" data-bind="click: ArchiveMessage">Archive</button>
                                                            </div>
                                                            <div className="messageHeader">
                                                                <div className="fieldGroup stacked">
                                                                    <div className="field inline">
                                                                        <label>From</label><span data-bind="text: DisplayFromAddress">scottm@threeriverssystems.com</span>
                                                                    </div>
                                                                    <div className="field inline">
                                                                        <label>Sent</label><span data-bind="text: DateCreatedJS">6/1/2015</span>
                                                                    </div>
                                                                    <div className="field inline">
                                                                        <label>To</label><span data-bind="text: ToAddress">TEMP</span>
                                                                    </div>
                                                                    <div className="field inline">
                                                                        <label>Subject</label><span className="ellipsis" data-bind="text: Subject">CRM Ad-Hoc Communication</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="messageBody">
                                                                <iframe src="about:blank" title="MessageBody" style={{width:'100%'}}></iframe>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li data-tab-content="archivedMessages" style={{display: 'none'}}>
                                        <div>
                                            <h2>Archived Messages</h2>
                                            <ul className="detailedList" id="archivedMessages" data-bind="foreach: ArchivedMessages"></ul>
                                        </div>
                                    </li>
                                    <li data-tab-content="composeMessage" data-bind="visible: NewMessageTabVisible()" style={{display: 'none'}}>
                                        <div className="newOrReplyMessage">
                                            <h2>New Message</h2>
                                            <div className="fRight">
                                                <button id="cancelNewMessage" className="cancelButton" data-bind="click: CancelMessage">Cancel</button>
                                                <button id="sendCurrentMessage" className="sendButton" data-bind="click: SendMessage">Send</button>
                                            </div>
                                            <form noValidate="novalidate">
                                                <div className="fieldGroup stacked">
                                                    <div className="field inline" style={{display: 'none'}}>
                                                        <label>From</label>
                                                        <input type="text" data-bind="value: $root.PrimaryEmailAddress" disabled="disabled" />
                                                    </div>
                                                    <div className="field inline">
                                                        <label>To</label>
                                                        <input type="text" data-bind="value: NewMessage().ToAddress" readOnly="readonly" />
                                                    </div>
                                                    <div className="field inline">
                                                        <label>Subject</label>
                                                        <input type="text" data-bind="value: NewMessage().Subject" />
                                                    </div>
                                                </div>
                                                <div className="fieldGroup stacked">
                                                    <div className="field inline">
                                                        <label>Message</label>
                                                        <textarea id="htmlReply" data-bind="richText: NewMessage().Html" data-ckeditortype="basic" style={{visibility: 'hidden', display: 'none'}}></textarea>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

export default CommunicationsWidget;
