import React, { Component } from 'react';
import '../FinancialAidWidget/FinancialAidWidget.css';

class FinancialAidWidget extends Component {
    render(){
        return(
            <div className="flexWidget smid lightBg" id="sm360FinancialAid">
                <div data-bind="template: { name: TemplateName('StudentFinancialAidTemplate_{0}'), data: FinancialAidSummary }" id="financialAidSection">
                    <div className="widgetHeader purple">
                        <span className="widgetIcon financialAidIcon" ></span>
                        <h2>Financial Aid</h2>
                    </div>
                    <div className="widgetContent" style={{display: 'block'}}>
                        <div data-bind="visible: !Loaded() &amp;&amp; !IsError()" style={{display: 'none'}}>Loading...</div>
                        <div data-bind="visible: IsError()" className="validationErrors" style={{display: 'none'}}>
                            <div className="fieldGroup">
                                <div className="field inline headerField">
                                    No Financial Aid Applies
                                </div>
                            </div>
                        </div>
                        <div data-bind="visible: Loaded() &amp;&amp; !IsError()">
                            <div className="fieldGroup">
                                <div className="field inline headerField">
                                    <label>Financial Aid Period</label><span data-bind="text: FinancialAidPeriodDisplay">2015 SAY</span>
                                </div>
                                <div className="field inline currency">
                                    <label>Total Awards</label><span data-bind="currency: TotalAwards, symbol: CurrencySymbol">$0.00</span>
                                </div>
                                <div className="field inline currency">
                                    <label>Cost of Attendance</label><span data-bind="currency: CostOfAttendance, symbol: CurrencySymbol">$23,499.00</span>
                                </div>
                                <div className="field inline currency">
                                    <label>Remaining Need</label><span data-bind="currency: RemainingNeed, symbol: CurrencySymbol">$23,499.00</span>
                                </div>
                                <div className="field inline currency">
                                    <label>Primary EFC</label><span data-bind="currency: PrimaryEFC, symbol: CurrencySymbol">$0.00</span>
                                </div>
                                <div className="field inline currency">
                                    <label>Alternate EFC</label><span data-bind="currency: AlternateEFC, symbol: CurrencySymbol">$0.00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FinancialAidWidget;