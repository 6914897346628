import React, { Component } from 'react';
import './NotificationWidget.css';

class NotificationWidget extends Component {

    render(){
        return(
            <div className="flexWidget smid lightBg" id="notificationsWidget">
                <div className="widgetHeader red">
                    <span className="widgetIcon notificationsIcon"></span>
                    <h2>Notifications</h2>
                </div>
                <div className="widgetContent">
                    <ul className="detailedList">
                        <li>
                            <div className="listItem">
                                <div className="itemSummary">
                                    <span>Apply for Housing</span>
                                    <span className="fullWrap">
                                        You may now begin the Housing process by visiting the following link. <a href="#" style={{display:'block',fontWeight:'bold',margin:'.5em 0',textDecoration:'underline'}}>My Housing</a>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default NotificationWidget;