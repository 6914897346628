import React, { Component } from 'react';
import '../BillingSummaryWidget/BillingSummaryWidget.css';

class BillingSummaryWidget extends Component {

    constructor(props){
        super(props);

        this.state = {
            

        }

    }

    render(){
        return(
            <div className="flexWidget medium lightBg" id="sm360Billing">
                <div id="billingSection">
                    <div className="widgetHeader green">
                        <span className="widgetIcon billingSummaryIcon" ></span>
                        <h2>Billing Summary</h2>
                    </div>
                    <div className="widgetContent">
                        <div style={{display: 'none'}}>Loading...</div>
                        <div className="validationErrors" style={{display: 'none'}}>
                            <ul>
                                <li>
                                    <h2 className="noResults">No Billing Summary Available</h2>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="fieldGroup responsive">
                                <div className="field">
                                    <label>Invoiced</label><span data-bind="currency: InvoicedAmount, symbol: CurrencySymbol">$28,130.00</span>
                                </div>
                                <div className="field">
                                    <label>Account Status</label><span data-bind="text: AccountStatusName">Active</span>
                                </div>
                                <div className="field">
                                    <label>Payment Plan</label><span data-bind="text: PaymentPlanName"></span>
                                </div>
                            </div>
                            <ul className="triBlock">
                                <li>
                                    <div className="field">
                                        <label>Pending</label><span data-bind="currency: PendingAmount, symbol: CurrencySymbol">$0.00</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="field">
                                        <label>Processing</label><span data-bind="currency: ProcessingAmount, symbol: CurrencySymbol">$23,135.00</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="field">
                                        <label>Posted</label><span data-bind="currency: PostedAmount, symbol: CurrencySymbol">$29,230.00</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="field">
                                        <label>Overall</label><span data-bind="currency: OverallAmount, symbol: CurrencySymbol">$52,335.00</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }




}

export default BillingSummaryWidget;