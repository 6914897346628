import React, { Component } from 'react';
import '../EnrollmentsWidget/EnrollmentsWidget.css';

class EnrollmentsWidget extends Component {

    render(){
        return(
            <div className="flexWidget medium lightBg" id="sm360Enrollments">
                <div data-bind="template: { name: TemplateName('StudentEnrollmentTemplate_{0}'), data: Courses }" id="enrollmentSection">
                    <div className="widgetHeader orange">
                        <span className="widgetIcon myCoursesIcon" ></span>
                        <h2>Enrollments</h2>
                    </div>
                    <div className="widgetContent">
                        <div data-bind="visible: !Loaded() &amp;&amp; !IsError()" style={{display: 'none'}}>Loading...</div>
                        <div data-bind="visible: IsError()" className="validationErrors" style={{display: 'none'}}>
                            <ul className="detailedList">
                                <li >
                                    <h2 className="noResults">No Enrollments</h2>
                                </li>
                            </ul>
                        </div>
                        <div data-bind="visible: Loaded() &amp;&amp; !IsError()">
                            <ul className="detailedList" data-bind="foreach: DataList">
                                <li data-hasdetails="true" style={{cursor: 'pointer'}} className="">
                                    <div className="listItem">
                                        <div className="field">
                                            <span data-bind="text: Title">SP2016 - New</span>
                                        </div>
                                        <span className="icon detailsIcon show"></span>
                                        <div className="itemDetails">
                                            <div data-bind="template: { name: $root.TemplateName('StudentScheduleTemplate_{0}'), data: CourseViewModel }" id="scheduleSection">
                                                <div className="widgetHeader orange" data-bind="visible: StandAlone()" style={{display: 'none'}}>
                                                    <h2>Schedule</h2>
                                                </div>
                                                <div className="widgetContent noMaxHeight">
                                                    <div data-bind="visible: !Loaded()" style={{display: 'none'}}>Loading...</div>
                                                    <div data-bind="visible: Loaded()">
                                                        <ul data-bind="attr: { class: StandAlone() ? 'detailedList' : '' }, foreach: DataList" data-singleopen="true" className="">
                                                            <li>
                                                                <div data-bind="attr: { class: $parent.StandAlone() ? 'listItem' : ''}" className="">
                                                                    <div className="itemSummary responsive">
                                                                        <span data-bind="text: CourseTitle">Foundations of Structured English Immersion </span>
                                                                        <span data-bind="text: CourseID">BLE220LEC</span>
                                                                        <div>
                                                                            <div className="field inline">
                                                                                <label>Enrollment Mode</label>
                                                                                <span data-bind="text: EnrollmentModeName">Credit</span>
                                                                            </div>
                                                                            <div className="field inline">
                                                                                <label>Clock Hours</label>
                                                                                <span data-bind="text: ClockHours"></span>
                                                                            </div>
                                                                            <div className="field inline">
                                                                                <label>Credit Hours</label>
                                                                                <span data-bind="text: CreditHours">3</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div data-bind="attr: { class: $parent.StandAlone() ? 'listItem' : ''}" className="">
                                                                    <div className="itemSummary responsive">
                                                                        <span data-bind="text: CourseTitle">Physiology for Engineers</span>
                                                                        <span data-bind="text: CourseID">BME235LEC</span>
                                                                        <div>
                                                                            <div className="field inline">
                                                                                <label>Enrollment Mode</label>
                                                                                <span data-bind="text: EnrollmentModeName">Pass/Fail</span>
                                                                            </div>
                                                                            <div className="field inline">
                                                                                <label>Clock Hours</label>
                                                                                <span data-bind="text: ClockHours"></span>
                                                                            </div>
                                                                            <div className="field inline">
                                                                                <label>Credit Hours</label>
                                                                                <span data-bind="text: CreditHours">4</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div data-bind="attr: { class: $parent.StandAlone() ? 'listItem' : ''}" className="">
                                                                    <div className="itemSummary responsive">
                                                                        <span data-bind="text: CourseTitle">2-D Design</span>
                                                                        <span data-bind="text: CourseID">ART112LEC</span>
                                                                        <div>
                                                                            <div className="field inline">
                                                                                <label>Enrollment Mode</label>
                                                                                <span data-bind="text: EnrollmentModeName">Pass/Fail</span>
                                                                            </div>
                                                                            <div className="field inline">
                                                                                <label>Clock Hours</label>
                                                                                <span data-bind="text: ClockHours"></span>
                                                                            </div>
                                                                            <div className="field inline">
                                                                                <label>Credit Hours</label>
                                                                                <span data-bind="text: CreditHours">3</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div data-bind="attr: { class: $parent.StandAlone() ? 'listItem' : ''}" className="">
                                                                    <div className="itemSummary responsive">
                                                                        <span data-bind="text: CourseTitle">Color</span>
                                                                        <span data-bind="text: CourseID">ART113LEC</span>
                                                                        <div>
                                                                            <div className="field inline">
                                                                                <label>Enrollment Mode</label>
                                                                                <span data-bind="text: EnrollmentModeName">Pass/Fail</span>
                                                                            </div>
                                                                            <div className="field inline">
                                                                                <label>Clock Hours</label>
                                                                                <span data-bind="text: ClockHours"></span>
                                                                            </div>
                                                                            <div className="field inline">
                                                                                <label>Credit Hours</label>
                                                                                <span data-bind="text: CreditHours">3</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div data-bind="attr: { class: $parent.StandAlone() ? 'listItem' : ''}" className="">
                                                                    <div className="itemSummary responsive">
                                                                        <span data-bind="text: CourseTitle">Forensic Anthropology (Writing Intensive)</span>
                                                                        <span data-bind="text: CourseID">ANTHRO3500LAB1.0</span>
                                                                        <div>
                                                                            <div className="field inline">
                                                                                <label>Enrollment Mode</label>
                                                                                <span data-bind="text: EnrollmentModeName">Credit</span>
                                                                            </div>
                                                                            <div className="field inline">
                                                                                <label>Clock Hours</label>
                                                                                <span data-bind="text: ClockHours"></span>
                                                                            </div>
                                                                            <div className="field inline">
                                                                                <label>Credit Hours</label>
                                                                                <span data-bind="text: CreditHours">3</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="icon detailsIcon show" data-bind="visible: $parent.StandAlone()"></span>
                                                                    <div data-bind="attr: { id: 'itemDetails' + CourseOfferingID(), class: $parent.StandAlone() ? 'itemDetails' : '' }" id="itemDetails4633" className="">
                                                                        <div className="isPhone">
                                                                            <ul className="phoneDetails" data-bind="foreach: Schedules">
                                                                                <li>
                                                                                    <span></span>
                                                                                    <span>
                                                                                        <span data-bind="text: PeriodOrDateRange">SP2016</span> |
                                                                                        <span data-bind="text: Timing">Recurring</span>
                                                                                    </span>
                                                                                    <span>
                                                                                        <span data-bind="text: RoomScheduleType"></span> |
                                                                                        <span data-bind="text: RoomInfo">Frasier Hall - 016</span>
                                                                                    </span>
                                                                                    <span data-bind="html: DaysAndTimesInfo">1/22/2016 - 5/13/2016<br />MWF 11:04 AM - 12:04 PM<br /></span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <table className="portalDefault notPhone">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Term</th>
                                                                                    <th>Room</th>
                                                                                    <th>Schedule</th>
                                                                                    <th>Faculty</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="field">
                                                                                            <span className="mainHeading" data-bind="text: PeriodOrDateRange">SP2016</span>
                                                                                        </div>
                                                                                        <div className="field">
                                                                                            <span data-bind="text: Timing">Recurring</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="field inline">
                                                                                            <span data-bind="text: RoomScheduleType"></span>
                                                                                        </div>
                                                                                        <div className="field inline">
                                                                                            <span data-bind="text: RoomInfo">Frasier Hall - 016</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="field inline" data-bind="html: DaysAndTimesInfo">1/22/2016 - 5/13/2016<br />MWF 11:04 AM - 12:04 PM<br /></div>
                                                                                        <div className="field inline">
                                                                                            &nbsp;
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="field inline">
                                                                                            &nbsp;
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

export default EnrollmentsWidget;